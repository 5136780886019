import { Suspense, lazy, useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { scroller } from 'react-scroll'; // Import scroller from react-scroll
import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Modals from './components/Modals/Modals';
import Notifications from './components/Notifications/Notifications';
import { useBodyOverflow, useUserSession } from './utils';
import './App.scss';
import 'react-tooltip/dist/react-tooltip.css';
import HAWCCookieConsent from './components/CookieConsent/CookieConsent';
import ComparatorBar from './components/ComparatorBar/ComparatorBar';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Helmet from './components/Helmet/Helmet';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RedirectToSecurity } from './utils/redirect';
import { Language } from './utils';

// Lazy load pages and components
const HomePage = lazy(() => import('./pages/IndexPage'));
const SectorsPage = lazy(() => import('./pages/SectorsPage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const ShoppingCart = lazy(() => import('./pages/ShoppingCart'));
const DraftsPage = lazy(() => import('./pages/DraftsPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const CategoriesPage = lazy(() => import('./pages/ShopPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(
  () => import('./pages/TermsAndConditionsPage')
);
const DistributorPage = lazy(() => import('./pages/DistributorPage'));
const SalesConditionsPage = lazy(() => import('./pages/SalesConditionsPage'));
const PricelistPage = lazy(() => import('./pages/PricelistPage'));
const TechAreaPage = lazy(() => import('./pages/TechAreaPage'));
const RequireAuth = lazy(() => import('./components/RequireAuth/RequireAuth'));

export const routeTranslations: Record<Language, Record<string, string>> = {
  en: {
    home: '/',
    register: 'register',
    sectors: 'sectors',
    security: 'security',
    categories: 'categories',
    product: 'product',
    dashboard: 'dashboard',
    shoppingCart: 'shopping-cart',
    drafts: 'drafts',
    resetPassword: 'reset-password',
    distributorArea: 'distributor-area',
    login: 'login',
    privacyPolicy: 'privacy-policy',
    termsAndConditions: 'terms-and-conditions',
    salesConditions: 'sales-conditions',
    techArea: 'tech-area',
    pricelist: 'pricelist',
    ourMission: 'our-mission',
    supportCenter: 'support-center',
  },
  pl: {
    home: '/',
    register: 'rejestracja',
    sectors: 'sektory',
    security: 'security',
    categories: 'kategorie',
    product: 'produkt',
    dashboard: 'panel',
    shoppingCart: 'koszyk',
    drafts: 'wersje-robocze',
    resetPassword: 'reset-password',
    distributorArea: 'strefa-dystrybutora',
    login: 'logowanie',
    privacyPolicy: 'polityka-prywatnosci',
    termsAndConditions: 'regulamin',
    salesConditions: 'warunki-sprzedazy',
    techArea: 'strefa-techniczna',
    pricelist: 'cennik',
    ourMission: 'nasza-misja',
    supportCenter: 'pomoc',
  },
};

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const lang = i18n.language.slice(0, 2) as Language; // Restrict to the Language type
  const routes = routeTranslations[lang] || routeTranslations['en']; // Default to 'en' if language is not supported

  useBodyOverflow();
  useUserSession();

  useEffect(() => {
    // Detect if URL contains a hash (e.g., /pl/#our-mission)
    if (location.hash) {
      const section = location.hash.substring(1); // Remove '#'

      // Scroll to the relevant section smoothly
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location]);

  // Redirect to language-specific route if visiting the root path
  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng')?.slice(0, 2) || 'en'; // Get the last selected language or default to 'en'

    if (location.pathname === '/') {
      navigate(`/${storedLang}${routes.home}`, { replace: true }); // Redirect to /pl or /en
    }
  }, [navigate, location.pathname, routes.home]);

  // Sync language based on URL and store the selected language in localStorage
  useEffect(() => {
    const currentLang = location.pathname.split('/')[1]; // Extract language from the URL
    if (currentLang && i18n.language !== currentLang) {
      i18n.changeLanguage(currentLang); // Update i18n language if URL and current language differ
    }
    localStorage.setItem('i18nextLng', currentLang || 'en'); // Persist language in localStorage
  }, [location.pathname, i18n]);

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <Helmet language={i18n.language || 'en'} />
      <ScrollToTop />
      <Header />
      <ComparatorBar />
      <Notifications />
      <Modals />
      <HAWCCookieConsent language={i18n.language || 'en'} />
      <div style={{ maxWidth: '1920px', margin: '0 auto' }}>
        <Suspense>
          <Routes>
            <Route path={`/:lang${routes.home}`} element={<HomePage />} />
            <Route path={`/:lang/${routes.register}`} element={<HomePage />} />
            <Route
              path={`/:lang/${routes.sectors}`}
              element={<RedirectToSecurity />}
            />
            <Route
              path={`/:lang/${routes.sectors}/:sectorName`}
              element={<SectorsPage />}
            />
            <Route
              path={`/:lang/${routes.categories}`}
              element={<CategoriesPage />}
            />
            <Route
              path={`/:lang/${routes.categories}/:categoryName`}
              element={<CategoriesPage />}
            />
            <Route
              path={`/:lang/${routes.categories}/:categoryName/:productId`}
              element={<ProductPage />}
            />
            <Route
              path={`/:lang/${routes.drafts}`}
              element={
                <RequireAuth>
                  <DraftsPage />
                </RequireAuth>
              }
            />
            <Route
              path={`/:lang/${routes.shoppingCart}`}
              element={
                <RequireAuth>
                  <ShoppingCart />
                </RequireAuth>
              }
            />
            <Route
              path={`/:lang/${routes.dashboard}`}
              element={
                <RequireAuth>
                  <DashboardPage />
                </RequireAuth>
              }
            />
            <Route
              path={`/:lang/${routes.resetPassword}`}
              element={<ResetPasswordPage />}
            />
            <Route
              path={`/:lang/${routes.distributorArea}`}
              element={<DistributorPage />}
            />
            <Route
              path={`/:lang/${routes.privacyPolicy}`}
              element={<PrivacyPolicyPage />}
            />
            <Route
              path={`/:lang/${routes.termsAndConditions}`}
              element={<TermsAndConditionsPage />}
            />
            <Route
              path={`/:lang/${routes.salesConditions}`}
              element={<SalesConditionsPage />}
            />
            <Route
              path={`/:lang/${routes.techArea}`}
              element={
                <RequireAuth>
                  <TechAreaPage />
                </RequireAuth>
              }
            />
            <Route
              path={`/:lang/${routes.pricelist}`}
              element={<PricelistPage />}
            />
            <Route
              path='/'
              element={<Navigate to={`/${lang}${routes.home}`} />}
            />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
};

export default App;
